import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import dataV from '@jiaminghi/data-view';
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';
import '@/common/flexible.js';
import './assets/scss/style.scss';
import echarts from 'echarts'
import VueApexCharts from 'vue-apexcharts'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.component('apexchart', VueApexCharts)
import axios from "axios";
//服务器
axios.defaults.baseURL="http://60.208.84.149:8082/prod-api/";
//axios.defaults.baseURL="http://127.0.0.1:8081/";
axios.interceptors.request.use(config =>{
  //拦截所有ajax请求，请求头加上token数据上传统计
  //Cookies
  config.headers.Authorization = 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjU3MjNjNGU1LWNjOGQtNDQ2NS1iMTgzLTJhZjVmZjU3ZTVhYSJ9.a8dN1b4tyMgASfDPRF9DH428p7vTQh8kKwox4DXi_SYduUCnPhjJWbWpInhkWooIdPoHzlqFRvo9MP_U-82WwQ';
  //config.headers.Authorization = window.sessionStorage.getItem("access_token");
  return config;
})
Vue.prototype.$http = axios;

Vue.use(dataV);
Vue.component('icon', Icon);
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false;
import moment from 'moment'; //导入模块
moment.locale('zh-cn'); //设置语言 或 moment.lang('zh-cn');
Vue.prototype.$moment = moment;//赋值使用
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
